import { FETCH_STREAM, FETCH_STREAM_FAILURE, FETCH_STREAM_SUCCESS, RESET_STREAM_STORE_STATE, STORE_STREAM, STORE_STREAM_FAILURE, STORE_STREAM_SUCCESS, UPDATE_STREAM, UPDATE_STREAM_FAILURE, UPDATE_STREAM_SUCCESS } from "./streamType"

const initialState = {
    streams: {
        data: [],
        meta: {}
    },
    streamsLoading: true,
    streamsError: {},
    streamStore: {},
    streamStoreLoading: false,
    streamStoreError: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_STREAM:
            return {
                ...state,
                streamsLoading: true
            }
        case FETCH_STREAM_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.payload
            }
        case FETCH_STREAM_FAILURE: 
            return {
                ...state,
                streamsLoading: false,
                streamsError: action.payload
            }
        case STORE_STREAM:
            return {
                ...state,
                streamStoreLoading: true
            }
        case STORE_STREAM_SUCCESS:
            return {
                ...state,
                streamStoreLoading: false,
                streamStore: action.payload
            }
        case STORE_STREAM_FAILURE:
            return {
                ...state,
                streamStoreLoading: false,
                streamStoreError: action.payload
            }
        case UPDATE_STREAM:
            return {
                ...state,
                streamStoreLoading: true
            }
        case UPDATE_STREAM_SUCCESS:
            return {
                ...state,
                streamStoreLoading: false,
                streamStore: action.payload
            }
        case UPDATE_STREAM_FAILURE:
            return {
                ...state,
                streamStoreLoading: false,
                streamStoreError: action.payload
            }
        case RESET_STREAM_STORE_STATE:
            return {
              ...state,
              streamStore: {},
              streamStoreLoading: false,
              streamStoreError: {},
            };
        default: return state;
    }
}

export default reducer;