import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	MenuItem,
	TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSegmentLists, fetchStream, streamStore, unsetError, updateStream } from '../../../../config/redux';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import MySnackbar from '../../../components/Snackbar';

const Create = ({ open, handleClose, streamEdit }) => {
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: '',
		variant: 'info',
	});
	const [stream, setStream] = useState(streamEdit || {});

	const dispatch = useDispatch();
	const { streamStoreLoading: loading, streamStoreError: error } = useSelector(
    (state) => state.streams
  );
	const { listsLoading: loadingSegment, lists: segments } = useSelector(state => state.segments);

	const handleDialogClose = () => {
		dispatch(unsetError());
		handleClose();
	};

	const handleSubmit = async () => {
		try {
			const res = await dispatch(streamStore(stream));
			setStream({});
			dispatch(fetchStream());
			setSnackbar({
				open: true,
				message: res.data.message,
				variant: 'success',
			});
		} catch (error) {
			setSnackbar({
				open: true,
				message: error.message,
				variant: 'error',
			});
		}
	};
	const handleUpdate = async () => {
		try {
			const res = await dispatch(updateStream(stream));
			dispatch(fetchStream());
			setSnackbar({
				open: true,
				message: res.data.message,
				variant: 'success',
			});
		} catch (error) {
			setSnackbar({
				open: true,
				message: error.message,
				variant: 'error',
			});
		}
	};

	useEffect(() => {
		loadSegments();
	}, []);

	const loadSegments = () => {
		dispatch(fetchSegmentLists());
	};

	const handleChange = event => {
		const newStream = { ...stream };
		newStream[event.target.name] = event.target.value;
		setStream(newStream);
	};

	const handleCloseSnackbar = () => {
		setSnackbar({
			open: false,
			variant: 'info',
			message: '',
		});
	};
	return (
		<>
			<MySnackbar
				open={snackbar?.open}
				onClose={handleCloseSnackbar}
				message={snackbar?.message || ''}
				variant={snackbar.variant || 'info'}
			/>
			<Dialog fullWidth maxWidth="sm" open={open} scroll="body">
				<DialogTitle onClose={true} onHandleClose={handleDialogClose}>
					{streamEdit ? `Edit CCTV` : `Tambah CCTV`}
				</DialogTitle>
				<DialogContent>
					<Grid container direction="column">
						<Grid item>
							<TextField
								fullWidth
								label="Nama CCTV"
								name="name"
								type="text"
								value={stream.name ?? ''}
								error={error?.errors?.name}
								helperText={error?.errors?.name}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item>
							<TextField
								fullWidth
								label="Ruas Tol"
								name="segment_id"
								type="text"
								select
								value={stream.segment_id ?? ''}
								error={error?.errors?.segment_id}
								helperText={error?.errors?.segment_id}
								onChange={handleChange}
							>
								{!loadingSegment &&
									segments.data.segments.map(list => (
										<MenuItem key={list.id} value={list.id}>
											{list.name}
										</MenuItem>
									))}
							</TextField>
						</Grid>
						<Grid item>
							<TextField
								fullWidth
								label="Stream URL"
								name="url"
								type="text"
								value={stream.url ?? ''}
								error={error?.errors?.url}
								helperText={error?.errors?.url}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item>
							<TextField
								fullWidth
								label="Arah"
								name="direction"
								type="text"
								value={stream.direction ?? ''}
								error={error?.errors?.direction}
								helperText={error?.errors?.direction}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<TextField
							fullWidth
							label="Status"
							name="is_active"
							select
							value={stream.is_active ?? '0'}
							error={error?.errors?.is_active}
							helperText={error?.errors?.is_active}
							onChange={handleChange}
						>
							<MenuItem key={'non-active'} value={false}>
								Tidak Aktif
							</MenuItem>
							<MenuItem key={'active'} value={true}>
								Aktif
							</MenuItem>
						</TextField>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={streamEdit ? handleUpdate : handleSubmit}
						variant="contained"
						style={{
							width: 120,
						}}
						disabled={loading}
						color="primary"
					>
						{loading ? <CircularProgress size={25} /> : streamEdit ? `Update` : `Simpan`}
					</Button>
					<Button onClick={handleDialogClose} variant="contained">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Create;
